<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <playerHonorEdit :_id="edit_id" @closePage="closeEditDrawer"></playerHonorEdit>
    </Drawer> 
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <!-- <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" /> -->
            <playerSearch v-model="player"></playerSearch>
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable 
        ref="table"
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getPlayerHonorList } from '@/api/olympics/player_honor';
import playerHonorEdit from './edit.vue';
import playerSearch from '../player/search.vue';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "player-honor-list",
    props: {
        
    },
    components: {
        playerHonorEdit,
        playerSearch,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            player: {
                id: 0,
                name: '',
            }, 
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '选手',
                    align: 'center',
                    key: 'player',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var player = currentRow.player;
                        return h('span', player.name );
                    }
                },
                {
                    title: '赛事',
                    align: 'center',
                    key: 'competition_name',
                    minWidth: 80,
                },
                {
                    title: '信息',
                    align: 'center',
                    key: 'info',
                    minWidth: 80,
                },
                {
                    title: '小运动名称',
                    align: 'center',
                    key: 'subitem_name',
                    minWidth: 80,
                },
                {
                    title: '类型',
                    align: 'center',
                    key: 'medal_type',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let medal_types = {
                            1: '金牌',
                            2: '银牌',
                            3: '铜牌'
                        }
                        return h('span', medal_types[ currentRow.medal_type ] || '未知' );
                    }
                },
                
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            var params = {
                search_key: this.search_key,
                page: this.current_page,
                player_id: this.player.id,
            }
            getPlayerHonorList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
        "player.id"(){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
